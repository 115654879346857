import './App.css';
import { useState, useEffect } from 'react';
import { Fragment } from 'react'
import { Disclosure, Menu, Transition } from '@headlessui/react'
import { Bars3Icon, BellIcon, XMarkIcon } from '@heroicons/react/24/outline';
import LaunchAnalysis from './Dashboards/LaunchAnalysis';
import logo from './GrowveLogoWhite.png';
import LogIn from './LogIn';
import { useCookies } from 'react-cookie';


const user = {
    name: 'Tom Cook',
    email: 'tom@example.com',
    imageUrl:
        'https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80',
}
const navigation = [
    { name: 'Dashboards', href: '#', current: true }
]
const userNavigation = [
    { name: 'Sign out', href: '#' },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}
function App() {
    const [cookies, setCookie, removeCookie] = useCookies(['isLoggedIn', 'user']);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isLoggedIn, setIsLoggedIn] = useState(cookies.isLoggedIn ? cookies.isLoggedIn : false);
    //const [isLoggedIn, setIsLoggedIn] = useState(true);
    const [user, setUser] = useState(cookies.user);
    console.log(Object.keys(cookies));
    console.log(`Value of cookies.user: ${cookies.user}`);

    function logOut() {
        setEmail('');
        setIsLoggedIn(false);
        setCookie('user', null, { path: '/' });
        setCookie('isLoggedIn', false, { path: '/' });
        setUser(null);
        
    }
    function logIn() {
        console.log('Started Function');
        let headers = new Headers();
        headers.set('Authorization', 'Basic ' + btoa(email + ':' + password));
        var test = fetch('https://dev.growve.tools:8080/dashboards/login', {
            method: 'GET',
            mode: 'cors',
            headers: headers
        })
            .then(response => {
                console.log('Started Fetch');
                if (response.ok) {
                    console.log('Got Response (200)');
                    setIsLoggedIn(true);
                    return response.json();
                } else {
                    console.log('Got Response (not 200)');
                    setIsLoggedIn(false);
                    return response.json();
                };
                
            })
            .then(json => {
                console.log('Got JSON');
                if (Object.keys(json).includes('user')) {
                    setCookie('user', json.user, { path: '/' });
                    setCookie('isLoggedIn', true, { path: '/' });
                    setPassword('');
                } else {
                    setCookie('isLoggedIn', false, { path: '/' });
                    setPassword('');
                }
                setUser(json.user);
            });
    }
  return (
    <div className="App">
          <div className="min-h-full">
              <Disclosure as="nav" className="bg-[#68a529]">
                  {({ open }) => (
                      <>
                          <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
                              <div className="flex h-16 items-center justify-between">
                                  <div className="flex items-center">
                                      <div className="flex-shrink-0">
                                          <img
                                              className="h-11 w-8"
                                              src={logo}
                                              alt="Growve"
                                          />
                                      </div>
                                      <div className="hidden md:block">
                                          <div className="ml-10 flex items-baseline space-x-4">
                                              {navigation.map((item) => (
                                                  <a
                                                      key={item.name}
                                                      href={item.href}
                                                      className={classNames(
                                                          item.current
                                                              ? 'bg-white text-[#68a529]'
                                                              : 'text-white hover:bg-white hover:bg-opacity-75 hover:text-[#68a529]',
                                                          'rounded-md px-3 py-2 text-sm font-medium'
                                                      )}
                                                      aria-current={item.current ? 'page' : undefined}
                                                  >
                                                      {item.name}
                                                  </a>
                                              ))}
                                          </div>
                                      </div>
                                  </div>
                                  <div className="hidden md:block">
                                      <div className="ml-4 flex items-center md:ml-6">
                                          {/* Profile dropdown */}
                                          <Menu as="div" className="relative ml-3">
                                              <div>
                                                  <Menu.Button className="relative flex max-w-xs items-center rounded-full bg-[#68a529] text-sm text-white focus:outline-none">
                                                      <span className="absolute -inset-1.5" />
                                                      <span className="sr-only">Open user menu</span>
                                                      <span className="">{user !== null && user !== undefined ? user.name : null}</span>
                                                  </Menu.Button>
                                              </div>
                                              <Transition
                                                  as={Fragment}
                                                  enter="transition ease-out duration-100"
                                                  enterFrom="transform opacity-0 scale-95"
                                                  enterTo="transform opacity-100 scale-100"
                                                  leave="transition ease-in duration-75"
                                                  leaveFrom="transform opacity-100 scale-100"
                                                  leaveTo="transform opacity-0 scale-95"
                                              >
                                                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                                                      {userNavigation.map((item) => (
                                                          <Menu.Item key={item.name}>
                                                              {({ active }) => (
                                                                  <button
                                                                      onClick={() => logOut()}
                                                                      className={classNames(
                                                                          active ? 'bg-gray-100' : '',
                                                                          'block px-4 py-2 text-sm text-gray-700'
                                                                      )}
                                                                  >
                                                                      {item.name}
                                                                  </button>
                                                              )}
                                                          </Menu.Item>
                                                      ))}
                                                  </Menu.Items>
                                              </Transition>
                                          </Menu>
                                      </div>
                                  </div>
                                  <div className="-mr-2 flex md:hidden">
                                      {/* Mobile menu button */}
                                      <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md bg-[#68a529] p-2 text-indigo-200 hover:bg-indigo-500 hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-indigo-600">
                                          <span className="absolute -inset-0.5" />
                                          <span className="sr-only">Open main menu</span>
                                          {open ? (
                                              <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                                          ) : (
                                              <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                                          )}
                                      </Disclosure.Button>
                                  </div>
                              </div>
                          </div>

                          <Disclosure.Panel className="md:hidden">
                              <div className="space-y-1 px-2 pb-3 pt-2 sm:px-3">
                                  {navigation.map((item) => (
                                      <Disclosure.Button
                                          key={item.name}
                                          as="a"
                                          href={item.href}
                                          className={classNames(
                                              item.current
                                                  ? 'bg-white text-[#68a529]'
                                                  : 'text-white hover:bg-white hover:bg-opacity-75 hover:text-[#68a529]',
                                              'block rounded-md px-3 py-2 text-base font-medium'
                                          )}
                                          aria-current={item.current ? 'page' : undefined}
                                      >
                                          {item.name}
                                      </Disclosure.Button>
                                  ))}
                              </div>
                              <div className="border-t border-indigo-700 pb-3 pt-4">
                                  <div className="flex items-center px-5 justify-center">
                                      
                                      <div className="justify-self-center">
                                          <div className="text-base font-medium text-white">{user !== null && user !== undefined ? user.name : null}</div>
                                      </div>
                                  </div>
                                  <div className="mt-3 space-y-1 px-2">
                                      
                                        <Disclosure.Button
                                            as="button"
                                            onClick={() => logOut()}
                                            className="block rounded-md px-3 py-2 text-base font-medium text-white hover:bg-indigo-500 hover:bg-opacity-75"
                                        >
                                            Sign Out
                                        </Disclosure.Button>
                                  </div>
                              </div>
                          </Disclosure.Panel>
                      </>
                  )}
              </Disclosure>

              <header className="bg-white shadow">
                  <div className="mx-auto max-w-7xl px-4 py-6 sm:px-6 lg:px-8">
                      <h1 className="text-3xl font-bold leading-tight tracking-tight text-gray-900">{isLoggedIn ? 'Launch Sales Dashboard' : null}</h1>
                  </div>
              </header>
              <main>
                  <div className="mx-auto max-w-7xl py-6 sm:px-6 lg:px-8">{isLoggedIn ? <LaunchAnalysis /> : <LogIn emailChange={(e) => setEmail(e.target.value)} passwordChange={(e) => setPassword(e.target.value)} password={password} email={email} login={() => logIn()} />}</div>
              </main>
          </div>
    </div>
  );
}

export default App;




