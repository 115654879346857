import { useState, useEffect } from 'react';
import { ChevronDownIcon, ChevronUpIcon } from '@heroicons/react/20/solid';

export default function Table(props) {
    const [columns] = useState(props.columns);
    const [originalData, setOriginalData] = useState(props.data);
    const [columnTypes] = useState(props.columnTypes);
    const [sortColumn, setSortColumn] = useState(null);
    const [data, setData] = useState(props.data);

    useEffect(() => {
        function updateOriginalData() {
            setOriginalData(props.data);
            setData(props.data);
        };
        updateOriginalData();
    }, [props.data]);

    function resetScroll(x, y) {
        window.scrollTo(x, y);
    }
    function sort(column) {
        var scrollx = window.scrollX;
        var scrolly = window.scrollY;
        var sortInfo = {};
        if (sortColumn === null || sortColumn.column !== column) {
            sortInfo.column = column;
            sortInfo.ascending = true;
            setSortColumn(sortInfo);
        } else if (sortColumn.column === column) {
            sortInfo.column = column;
            sortInfo.ascending = !sortColumn.ascending
            setSortColumn(sortInfo);
        };
        var newData;
        if (sortInfo.ascending) {
            newData = originalData.sort(function (a, b) {
                if (columnTypes[column] === 'string') {
                    if (a[column] > b[column]) {
                        return -1;
                    } else if (b[column] > a[column]) {
                        return 1;
                    } else {
                        return 0;
                    };
                } else {
                    if (a[`${column}Raw`] > b[`${column}Raw`]) {
                        return -1;
                    } else if (b[`${column}Raw`] > a[`${column}Raw`]) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            });
            setData(newData);
        } else {
            newData = originalData.sort(function (a, b) {
                if (columnTypes[column] === 'string') {
                    if (b[column] > a[column]) {
                        return -1;
                    } else if (a[column] > b[column]) {
                        return 1;
                    } else {
                        return 0;
                    };
                } else {
                    if (b[`${column}Raw`] > a[`${column}Raw`]) {
                        return -1;
                    } else if (a[`${column}Raw`] > b[`${column}Raw`]) {
                        return 1;
                    } else {
                        return 0;
                    }
                }
            });
            setData(newData);
        }
        resetScroll(scrollx, scrolly);
    }
    return (
        <div className="h-full overflow-y-scroll overflow-x-hidden" style={{overflowAnchor: 'none'} }>
            <div className="mt-8 flow-root">
                <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                        {(originalData === null || originalData === undefined) ? <span>Get Data First</span> : <table className="min-w-full divide-y relative divide-gray-300">
                            <thead className='sticky top-0'>
                                <tr>
                                    {columns.map((column, idx) => (
                                        <th key={`data_table_column_header_${column}`} scope="col" className="py-3.5 pl-4 pr-3 text-wrap w-32 text-left text-sm font-semibold text-white bg-dark-tremor-background sm:pl-0">
                                            <button className="group w-full font-bold flex text-center justify-center">
                                                {column}
                                                <span onClick={() => sort(column)} className="invisible ml-2 flex-none rounded text-white group-hover:visible group-focus:visible">
                                                    {(sortColumn === null || (!sortColumn.ascending && sortColumn.column === column)) ? <ChevronDownIcon className="h-5 w-5" aria-hidden="true" /> : <ChevronUpIcon className="h-5 w-5" aria-hidden="true" />}
                                                </span>
                                            </button>
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white z-0">
                                {data.map((row, idx) => (
                                    <tr className='even:bg-dark-tremor-background/80 odd:bg-dark-tremor-background text-white' key={`data_table_row_${idx}`}>
                                        {columns.map((column, i) => (
                                            <td key={`data_table_row_${idx}_${Math.random()}`} className="text-wrap w-32 text-center py-4 pl-4 pr-3 text-xs font-medium sm:pl-0">
                                                {row[column]}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>}
                    </div>
                </div>
            </div>
        </div>
    )
}
